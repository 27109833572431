<template>
  <div>
    <label for="surname">苗字：</label>
    <input type="text" id="surname" v-model="surname" />
    <br>
    <label for="givenname">名前：</label>
    <input type="text" id="givenname" v-model="givenname" />
    <br>
    <button @click="calculate">計算</button>
    <div>
      <p>処理結果：</p>
      <pre>{{ processedData }}</pre>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      surname: '', // テキスト入力の初期値
      givenname: '', // テキスト入力の初期値
      processedData: '' // 処理結果の初期値
    };
  },
  methods: {
    async calculate() {
        try {
            const response = await axios.post("/api/calc", {
                arg1: this.surname,
                arg2: this.givenname,
            });
            this.processedData = JSON.stringify(response.data, null, 2);
        } catch (error) {
            this.result = `エラーが発生しました: ${error.message}`;
        }
    },
  },
};
</script>

