<!-- App.vue -->
<template>
  <div id="app">
    <!-- HelloWorldコンポーネントを利用 -->
    <NameInput />
  </div>
</template>


<script>
import NameInput from './components/NameInput.vue';

export default {
  name: 'App',
  components: {
    NameInput
  }
}
</script>


